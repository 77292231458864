import React from "react"

import { Title } from "../components/common"
import { Layout, SEO } from "../components/Layout"
import { Practice } from "../components/Practices"

const Practices = () => (
  <Layout>
    <SEO title="Practices" />

    <Title
      name="What We Do"
      // subTitle="Rutrum quisque non tellus orci ac auctor augue."
      img="susan-yin-I7I4cir4dTc.jpg"
    />

    <div className="mt-16">
      <Practice title="Property Insurance Claims">
        <p>
          When an insured suffers damage to their property, it can be a
          confusing and stressful time. Many assume that their insurance
          companies will be there for them in their time of need. Unfortunately,
          that is not always the case. Most Florida insurers operate as
          for-profit businesses, and when they pay to repair your property, it
          affects their bottom line.
        </p>

        <p>
          We represent clients throughout the state of Florida who have suffered
          a wide range of property damage, including hurricane, wind, hail,
          tornado, sinkhole, fire, mold, lightning, broken pipes, and vandalism.
          If your property has experienced damage and you have yet to report a
          claim, or if you have already been denied or underpaid for your
          damages, it doesn’t cost anything to retain our services.
        </p>

        <p>
          Don’t walk away from your claim because of the hassle of dealing with
          an insurance agent or the insurance company rendering an unjust
          coverage determination. We’re here to bring the fight to your
          insurance company, and we only get paid if you get paid.
        </p>

        <p>
          It is important to fully understand your rights as well as your
          insurance company’s obligations. Call today for a free consultation.
        </p>
      </Practice>

      <Practice title="Bad Faith Litigation" dark>
        <p>
          Florida law obligates insurers to handle a policy holder’s claim in
          good faith. Specifically, an insurance company must properly
          investigate claims in a timely manner, be upfront regarding an
          insured’s rights under a policy, pay claims when reasonably required
          to do so, and fully explain the reasons behind a denial of coverage.
          These laws are in place to hold insurance companies accountable, and
          are necessary because insurers do not always operate in the best
          interest of the insured.
        </p>
        <p>
          If your insurance company has handled your claim in bad faith and in
          violation of Florida law, you may be entitled to monetary compensation
          in addition to the cost of damage repairs. Contact us to set up a
          complimentary consultation to discuss your legal options.
        </p>
      </Practice>

      <Practice title="Condominium Association Disputes">
        <p>
          If you own or rent a condominium, your association has an obligation
          to keep the common elements functional so they serve their intended
          purpose. Common elements often include roofing systems, windows,
          drywall, balconies, pipes, electrical systems, and exterior finishes.
          The association’s failure to maintain or repair these common elements
          may result in damage to your individual unit.
        </p>
        <p>
          If your property has been damaged as a result of your condominium
          association’s repeated failure to maintain common elements, call today
          to discuss the association’s obligations to perform repairs, and your
          right to compensation.
        </p>
      </Practice>
    </div>
  </Layout>
)

export default Practices
