import React from "react"

export const Practice = props => {
  const { children, dark, title } = props

  const makeId = title => {
    return title.replace(/ /g, "-").toLowerCase()
  }

  return (
    <div className={dark ? "bg-gray-50" : ""}>
      <article className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 lg:px-8">
        <h3
          id={makeId(title)}
          style={{ scrollMargin: "4rem" }}
          className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 header-underline sm:text-4xl sm:leading-10"
        >
          {title}
        </h3>
        <div className="mt-8 space-y-4 text-xl leading-7 text-gray-500">
          {children}
        </div>
      </article>
    </div>
  )
}
